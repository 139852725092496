<template>
  <div class="location">
    <Header :pageId="pageId" :cId="cId" />
    <Form :pageName="pageName" />
    <Pay />
    <Row />
    <Banner />
    <Fast />
    <Recommend />
    <FAQ />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Form from "@/components/form/Form";
import Row from "@/components/row_bg/RowBg";
import Recommend from "@/components/recommend/Recommend";
import Banner from "@/components/banner/Banner";
import FAQ from "@/components/faq/Faq";
import Fast from "@/components/fast/Fast";
import Footer from "@/components/footer/Footer";
import Pay from "@/components/pay/Pay";
export default {
  components: {
    Header,
    Form,
    Row,
    Recommend,
    Banner,
    FAQ,
    Fast,
    Footer,
    Pay,
  },
  data() {
    return {
      pageId: "",
      cId: "",
      pageName: "",
      cPageName: "",
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        this.pageId = val.query.id;
        this.cId = val.query.cId;
      },
    },
  },
  created() {
    this.pageId = this.$route.query.id;
    this.cId = this.$route.query.cId;
    this.pageName = this.$route.query.name;
  },
};
</script>

<style scoped lang="less">
.location {
  width: 100%;
  position: relative;
}
</style>
